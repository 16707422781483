'use client';

import { TextInputProps } from '@payloadcms/ui/fields/Text';
import { FieldError } from '@payloadcms/ui/forms/FieldError';
import { FieldLabel } from '@payloadcms/ui/forms/FieldLabel';
import { useField } from '@payloadcms/ui/forms/useField';
import { PaginatedResult, Tournament } from '@spikemark/rest-api';
import { useFetch } from '@spikemark/shared-hooks';
import { ComboboxAsync } from '@spikemark/ui/components/ui/combobox-async';
import { FC } from 'react';
import { useFieldProps } from '@payloadcms/ui/forms/FieldPropsProvider';
import { withCondition } from '@payloadcms/ui/forms/withCondition';
const searchTournaments = async (keywords: string) => {
  const result = await fetch(`/api/tournaments?keywords=${keywords}`);
  const data = (await result.json()) as PaginatedResult<Tournament>;
  return data.results.map(x => ({
    label: x.tournamentName,
    value: x.tournamentId
  }));
};

// this needs to be wrapped in the `withCondition` HOC in order to be dynamic.
// Dynamic in the sense that it can be shown/hidden based on other CMS fields
// See in in use at `collections/pages.ts`
export const TournamentField: FC<TextInputProps> = withCondition(({
  label,
  required,
  validate
}) => {
  const {
    path
  } = useFieldProps();
  const {
    initialValue,
    value = '',
    setValue,
    errorMessage,
    showError
  } = useField({
    path,
    validate
  });
  const classes = [showError && 'error', 'pb-6'].filter(Boolean).join(' ');
  const handleChange = (value: string) => {
    setValue(parseInt(value, 10));
  };
  const {
    data: initialTournament
  } = useFetch<Tournament>(`/api/tournaments/${initialValue}`, 'GET', {
    skip: !initialValue
  });
  return <div className={classes}>
        <FieldLabel htmlFor={path} label={label} required={required} />
        <FieldError showError={showError} message={errorMessage} />
        <ComboboxAsync fetcher={searchTournaments} value={String(value)} onChange={handleChange} emptyMessage="No tournaments found" placeholder="Search for a tournament..." formatLabel={() => initialTournament && value === initialValue ? initialTournament.tournamentName : ''} />
      </div>;
});