'use client';

import { TextInputProps } from '@payloadcms/ui/fields/Text';
import { FieldError } from '@payloadcms/ui/forms/FieldError';
import { FieldLabel } from '@payloadcms/ui/forms/FieldLabel';
import { useFieldProps } from '@payloadcms/ui/forms/FieldPropsProvider';
import { useField } from '@payloadcms/ui/forms/useField';
import { SchoolTeam, SearchResult } from '@spikemark/rest-api';
import { useFetch } from '@spikemark/shared-hooks';
import { ComboboxAsync } from '@spikemark/ui/components/ui/combobox-async';
import { FC } from 'react';
const renderLabel = (team: SchoolTeam): string => {
  return team.schoolName + ` (${team.division} ${team.gender})`;
};
const searchSchools = async (keywords: string) => {
  const result = await fetch(`/api/search/teams?query=${keywords}`);
  const data = (await result.json()) as SearchResult;
  return data.results?.map((x: SchoolTeam) => ({
    label: renderLabel(x),
    value: x.schoolId
  })) ?? [];
};
export const SchoolField: FC<TextInputProps> = ({
  label,
  required,
  validate
}) => {
  const {
    path
  } = useFieldProps();
  const {
    initialValue,
    value = '',
    setValue,
    errorMessage,
    showError
  } = useField({
    path,
    validate
  });
  const classes = [showError && 'error', 'pb-6'].filter(Boolean).join(' ');
  const handleChange = (value: string) => {
    setValue(parseInt(value, 10));
  };
  const {
    data: initialSchoolResult
  } = useFetch<SearchResult>(`/api/search/teams?schoolId=${initialValue}`, 'GET', {
    skip: !initialValue
  });
  const initialSchool = initialSchoolResult?.results?.[0] as SchoolTeam;
  return <div className={classes} data-sentry-component="SchoolField" data-sentry-source-file="school-field.tsx">
      <FieldLabel htmlFor={path} label={label} required={required} data-sentry-element="FieldLabel" data-sentry-source-file="school-field.tsx" />
      <FieldError showError={showError} message={errorMessage} data-sentry-element="FieldError" data-sentry-source-file="school-field.tsx" />
      <ComboboxAsync className="w-full" fetcher={searchSchools} value={String(value)} onChange={handleChange} emptyMessage="No schools found" placeholder="Search for a school..." formatLabel={() => initialSchool && value === initialValue ? renderLabel(initialSchool) : ''} data-sentry-element="ComboboxAsync" data-sentry-source-file="school-field.tsx" />
    </div>;
};