'use client';

import { FetchWithGuardRequestInit, useClientConfig } from '@spikemark/core';
import { useDeepMemo } from '../use-deep-memo';
import { useFetcher } from './use-fetcher';
import { UseRemoteHookResult, UseRemoteOptions, useRemote } from './use-remote';

export type UseFetchHookResult<T> = UseRemoteHookResult<T>;
export type UseFetchOptions<T, TParams extends Record<string, any>> = UseRemoteOptions<T, TParams>;
export type UseFetchRequestInit = FetchWithGuardRequestInit;

export function useFetch<T, TParams extends Record<string, any> = Record<string, any>>(
  path: string,
  method: RequestInit['method'] = 'GET',
  options?: UseFetchOptions<T, TParams>,
  init?: UseFetchRequestInit
): UseFetchHookResult<T> {
  const { cacheRevalidate, disableCache, fetchRequestMaxRetries, fetchRequestTimeout } =
    useClientConfig();
  const key = `${path}${options?.params ? `:${JSON.stringify(options.params)}` : ''}`;
  options = useDeepMemo({
    ...options,
    key,
  });
  init = useDeepMemo({
    ...init,
    cache: disableCache ? 'no-cache' : 'default',
    next: {
      revalidate: disableCache ? 0 : cacheRevalidate,
    },
    retries: fetchRequestMaxRetries,
    timeout: fetchRequestTimeout,
  });
  const fetcher = useFetcher<T, TParams>(path, method, init);
  return useRemote(fetcher, options);
}
