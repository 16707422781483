'use client';

import { useCallback } from 'react';
import { FetchWithGuardRequestInit, fetchWithGuard } from '@spikemark/core';

export function useFetcher<T, TParams extends Record<string, any> = Record<string, any>>(
  path: string,
  method: RequestInit['method'] = 'GET',
  init?: FetchWithGuardRequestInit
): (params: TParams) => Promise<T> {
  return useCallback(
    (params: TParams) => fetchWithGuard<T, TParams>(path, method, params, init),
    [path, init]
  );
}
